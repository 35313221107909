import NavBar from "./NavBar";
import Footer from "./Footer";
import Content from "./Content";

function LandingIntro() {
    return (
        <div className="landing-intro">
            <div className="text-container">
                <h1>DEGREE APPRENTICE HUB</h1>
                <p>Unfiltered truth about degree apprenticeships, by a degree apprentice, with a degree</p>
            </div>
        </div>
    )
}


function Landing() {
    return (
        <div className="landing-content">
            <NavBar />
            <LandingIntro />
            <Content />
            <Footer />
        </div>
    );
}

export default Landing;