// content components
function StarterPack() {
    const ex_cover_letter = process.env.PUBLIC_URL + '/customer_resources/Software_Degree_Apprenticeship_Example.pdf';

    return (
        <div className="StarterPack">
            <h2>STARTER PACK</h2> {/* centred text large*/}
            <p>Ready to apply? This pack is what you need to get started</p>
            <div>
                <a href="https://www.youtube.com/watch?v=VDX79EqRjxs&t">
                    <button>Application Tutorial</button>
                </a>
                <a href="https://www.prospects.ac.uk/job-profiles/browse-a-to-z">
                    <button>Job Profiles</button>
                </a>
                <a href="https://www.prospects.ac.uk/careers-advice/cvs-and-cover-letters/example-cvs/skills-based-cv-example">
                    <button>Template CV</button>
                </a>
                <a href={ ex_cover_letter } download="Software_Degree_Apprenticeship_Example.pdf">
                    <button>Template Cover Letter</button>
                </a>
                <a href="https://www.gradcracker.com/search/all-disciplines/degree-apprenticeships">
                    <button>Gradcracker Jobs</button>
                </a>
                <a href="https://uk.indeed.com/jobs?q=degree+apprenticeship&l=&from=searchOnHP&vjk=77847139a79eaf8e">
                    <button>Indeed Jobs</button>
                </a>
            </div>
        </div>
    )
}


function WhatYouDo() {
    return (
        <div className="row align-items-center" id="WhatYouDo">
            <div className="col-sm-3">
                <h3>WHAT DEGREE APPRENTICES ACTUALLY DO?</h3>
            </div>                
            <div className="col-sm-4">
                <div>
                    <h6><strong>OVERALL</strong></h6>
                    <p>
                    Imagine you're doing a Digital Technology Solutions (DTS) Degree Apprenticeship at Amazon while studying part-time at the University of Oxford.
                    Over four years, you'll work continuously at Amazon and attend university block weeks (9-4pm, Mon-Fri) every 5-7 weeks, 
                    except in the summer (no breaks for you!).
                    You'll be in the office 2-3 days a week (this varies by department), and university weeks alternate between in-person and online.
                    <br></br><br></br>
                    It's a great opportunity, with a 95% chance of landing a job if you nail it, <strong>but no guarantees!</strong>
                    </p>
                </div>
                <div>
                    <h6><strong>IN THE OFFICE</strong></h6>
                    <p>
                        So, at work, you work. Most apprentices have it straightforward, but for me, it's a bit more complex.
                        Other companies might do the same, so being over-prepared is key. Your course modules might not match your department perfectly.
                        For instance, I learned Python and C++ at uni, but my department mainly uses Java.
                        Fortunately, OOP concepts and some data types are consistent across languages.
                        Plus, I had to pick up Google Cloud Platform (GCP) and GitLab CI/CD in my first year, you guessed it - also not covered in uni modules.
                        <br></br><br></br>
                        My work allows 6-month placements in different teams, either within my home department or elsewhere.
                        They are internally reviewed with my manager and an apprentice lead - I present the projects I've completed in that time.
                        These placements are designed to target Key Skills and Behaviors (KSBs), and I log daily activities in a spreadsheet to 
                        track my progress and prep for presentations.
                        Myself and All other apprentices in the department also meet bi-weekly with the function apprentice lead.
                        One person presents their work so everyone gets a chance to see what else goes on in the function/company.
                        Don't forget all the agile ceremonies, bi-weekly 1-2-1s with your manager, and mentor sessions.
                        <br></br><br></br>
                        It's a lot, but it's all part of the journey!
                    </p>
                </div>
            </div>
            <div className="col-sm-4">
                <div>
                    <h6><strong>AT UNIVERSITY</strong></h6>
                    <p>
                        I know you care more about real-world experience (otherwise you wouldn't be considering a degree apprenticeship), 
                        but don't sleep on the university side of things.
                        <br></br><br></br>
                        You'll dive into 1-2 modules per block week, every 5-7 weeks.
                        Expect to cover programming (C++, Python, Java etc.), database design, network security, and business processes.
                        Don't stress too much about the math — this isn't a comp sci degree; it's more like applied software engineering.
                        You'll also tackle topics like machine learning, AI, Software Development Lifecycle, CI/CD, IoT, Network Protocols, etc.
                        After each block week, you'll have assignments to complete in your own time, ranging from coursework essays (~2500 words),
                        solution design/implementation (like building a web-app), presentations, or exams.
                        These can be solo or group projects.
                        <br></br><br></br>
                        Over the 4 years, you need to demonstrate ~30+ Key Skills and Behaviors (KSBs).
                        Think of these as essential skills like "writing quality code that follows syntax guidelines & industry best practices."
                        You prove this through assignment results, an End Point Assessment (EPA) with case studies, and a final year project at work.
                        The EPA involves an interview and case studies backed by witness testimonies from your manager or mentor.
                        Plus, you'll log daily activities related to KSBs using an app called OneFile. It sounds easy, but trust me, it's a grind.
                        This logging helps during tripartite meetings every 3 months with your uni tutor and work manager, 
                        where you discuss any issues and complete scorecards, ranking yourself on each KSB.
                    </p>
                </div>
                <div>
                    <h6><strong>CLOSING OFF</strong></h6>
                    <p>
                        Being real — we all want to live up soft life. For some, a Degree Apprenticeship will be just that.
                        For others, it will be challenging.
                        <br></br><br></br>
                        Consider this your guide to compare it with the typical uni experience.
                        When I went to university, I partied, played football, and chilled — and still got a degree.
                        I could do that again... but here, I can't be complacent.
                        At work, real professionals rely on me, and there are real goals to achieve.
                        If you want opportunities to gain actual work experience and can handle the admin, graft, and sacrifice,
                        then a Degree Apprenticeship is for you.
                        If your industry doesn't offer them, or you want some time to figure things out and party a bit, <strong>look elsewhere!</strong>
                        <br></br><br></br>
                        Thanks for reading my spiel. Good luck!
                    </p>
                </div>
            </div>
        </div>
    )
}


function Why() {
    return (
        <div className="Why">
            <h6><strong>WHY I MADE THIS WEBSITE?</strong></h6>
            <p>
                Firstly, I want to inspire people to be better versions of themselves.
                Part of doing that is by giving people the best and most accurate information.<br></br>
                Social Media can sometimes hide the truth, but I just want to give you an honest perspective of
                how degree apprenticeships go, <br></br>
                and provide a platform for support, relatable content, and networking.
                <br></br><br></br>
                ...also this is my 1st React App and I built it in 2 days
            </p>
        </div>
    )
}


// final content component comprising components from above
function Content() {
    const up_arrow = process.env.PUBLIC_URL + '/static/images/up-arrow.png'
    
    return (
        <div className="content-container">
            <StarterPack />
            <WhatYouDo />
            <Why />
            <a id="up-arrow" href="#main-nav"> {/* may change this after more content*/}
                <img src={up_arrow} alt="degree-apprentice-button" />
            </a>
        </div>
    )
}

export default Content