
function NavBar() {
    // const tiktok_logo = process.env.PUBLIC_URL + '/static/images/tiktok-logo.png'
    const linkedin_logo = process.env.PUBLIC_URL + '/static/images/linkedin-logo.png'
    const youtube_logo = process.env.PUBLIC_URL + '/static/images/youtube-logo.png'

    return (
        <div className="container-fluid">
            <div className="row align-items-center" id="main-nav">
                <div className="col-sm-4" id="nav-left">
                    <a href="/">#apprenticelife</a>
                </div>
                <div className="col-sm-4" id="nav-center"></div>
                <div className="col-sm-4" id="nav-right">
                    <a href="https://www.youtube.com/channel/UCfcnRgIuCi5mtYJCCPnbrkQ">
                           <img src={youtube_logo} alt="youtube-degree-apprentice" />
                    </a>
                    <a href="https://www.linkedin.com/in/illack-chiti/">
                           <img src={linkedin_logo} alt="linkedin-degree-apprentice" />
                    </a>
                    {/* <a href="/">
                           <img src={tiktok_logo} alt="tiktok-degree-apprentice" />
                    </a> */}
                </div>
            </div>
        </div>
        
    );
}

export default NavBar;